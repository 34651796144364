<template>
    <form @submit.prevent>
        <p class="promo" v-if="clientCount > 1">{{ "client_title" | localize }}</p>
        <p class="promo" v-else>{{ "rc_s3p4_title" | localize }}</p>

        <p class="promo" v-if="clientCount > 1">
            {{ "s3p6_text_5" | localize }} {{ clientNumber }}
        </p>

        <!-- Имя и пол -->
        <div class="form__group">
            <v-text-field
              v-model="client.name"
              @input="checkForm($v.client.name)"
              @blur="nextElementfocus('name', 'sex')"
              @keyup.enter="$event.target.blur()"
              :class="{ invalid: $v.client.name.$dirty && !$v.client.name.required }"
              :label="'s3p6_label_1' | localize"
              :placeholder="'s3p6_placeholder_1' | localize"
              :hint=" ($v.client.name.$dirty && !$v.client.name.required ? 'form_reqiered' : 'empty') | localize"
              ref="name"
              outlined
            ></v-text-field>

            <v-select
              v-model="client.sex"
              @change="checkForm($v.client.sex); nextElementfocus('sex', 'date')"
              @blur="nextElementfocus('sex', 'date')"
              @keyup.enter="$event.target.blur()"
              :items="getList('s3p6_select_3_value_', 2)"
              :class="{ invalid: $v.client.sex.$dirty && !$v.client.sex.required }"
              item-text="name"
              item-value="value"
              :label="'s3p6_label_2' | localize"
              :placeholder="'select' | localize"
              :menu-props="{ offsetY: true }"
              append-icon="icon-arrow-down"
              ref="sex"
              outlined
            ></v-select>
        </div>

        <!-- Дата рождения и образование -->
        <div class="form__group">
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  v-model="client.dateFormatted"
                  @change="checkForm($v.client.dateFormatted)"
                  @focus="client.date = parseDate(client.dateFormatted)"
                  @blur="client.date = parseDate(client.dateFormatted); nextElementfocus('date', 'education')"
                  @keyup.enter="$event.target.blur()"
                  v-mask.hide-on-empty="dateMask"
                  class="input_number v-input-calendar"
                  :class="{ invalid: ($v.client.date.$dirty && !$v.client.date.required) || ($v.client.date.$dirty && !$v.client.date.validDate) }"
                  :hint="hintArray($v.client.date, [{rule: $v.client.date.required, message: 'form_reqiered'}, {rule: $v.client.date.validDate, message: 'form_wrong_date'}]) | localize"
                  :label="'s3p6_label_3' | localize"
                  placeholder="28 / 10 / 2020"
                  outlined
                  persistent-hint
                  append-icon="icon-calendar"
                  ref="date"
                  v-bind="attrs"
                  v-on="on"
                  ></v-text-field>
              </template>
              <v-date-picker
                  v-model="client.date"
                  no-title
                  full-width
                  :min="dateFrom"
                  :max="dateTo"
                  @input="menu = false; client.dateFormatted = formatDate(client.date)"
              ></v-date-picker>
            </v-menu>

            <v-select
              v-model="client.education"
              @change="checkForm($v.client.education); nextElementfocus('education', 'city')"
              @blur="nextElementfocus('education', 'city')"
              @keyup.enter="$event.target.blur()"
              :items="getList('education_select_value_', 9)"
              :class="{ invalid: $v.client.education.$dirty && !$v.client.education.required }"
              item-text="name"
              item-value="value"
              :label="'education' | localize"
              :placeholder="'select' | localize"
              :menu-props="{ offsetY: true }"
              append-icon="icon-arrow-down"
              ref="education"
              outlined
            ></v-select>
        </div>

        <!-- Адрес проживания -->
        <p class="question">{{ "s3p6_label_5" | localize }}</p>
        <div class="form__group">
            <v-autocomplete
            v-model="client.city"
            :items="cities"
            @change="checkForm($v.client.city); nextElementfocus('city', 'street')"
            @blur="nextElementfocus('city', 'street')"
            @keyup.enter="$event.target.blur()"
            :class="{ invalid: $v.client.city.$dirty && !$v.client.city.required }"
            item-text="name"
            item-value="value"
            cache-items
            :label="'city' | localize"
            :placeholder="'select' | localize"
            append-icon="icon-arrow-down"
            ref="city"
            outlined
            ></v-autocomplete>

            <v-text-field
              v-model="client.street"
              @input="checkForm($v.client.street)"
              @blur="nextElementfocus('street', 'house')"
              @keyup.enter="$event.target.blur()"
              :class="{ invalid: ($v.client.street.$dirty && !$v.client.street.required) || ($v.client.street.$dirty && !$v.client.street.alpha) }"
              :hint="hintArray(
                  $v.client.street, [
                    {rule: $v.client.street.required, message: 'form_reqiered'},
                    {rule: $v.client.street.alpha, message: 'form_wrong_abc'}
                  ]
                ) | localize"
              :label="'street' | localize"
              :placeholder="'street' | localize"
              ref="street"
              outlined
            ></v-text-field>
            <!-- @keyup="parseInput('street', client.street)" -->
        </div>
        <div class="form__group">
            <v-text-field
            v-model="client.house"
            @input="checkForm($v.client.house)"
            @blur="$v.client.house.$touch()"
            :class="{ invalid: $v.client.house.$dirty && !$v.client.house.required }"
            :hint=" ($v.client.house.$dirty && !$v.client.house.required ? 'form_reqiered' : 'empty') | localize"
            :label="'house' | localize"
            :placeholder="'house' | localize"
            ref="house"
            outlined
            ></v-text-field>
        </div>

        <!-- Общая информация -->
        <p class="question">{{ "common_info" | localize }}</p>
        <div class="form__group" v-if="service == 'mortgage' || service == 'refMortgage'">
            <!-- Источник дохода -->
            <v-select
              v-model="client.source"
              @change="checkForm($v.client.source); nextElementfocus('source', 'insurance'); client.work = null"
              @blur="nextElementfocus('source', 'insurance')"
              @keyup.enter="$event.target.blur()"
              :items="getList('s3p6_select_1_value_', 4)"
              :class="{ invalid: $v.client.source.$dirty && !$v.client.source.required }"
              item-text="name"
              item-value="value"
              :label="'s3p6_label_4' | localize"
              :placeholder="'select' | localize"
              :menu-props="{ offsetY: true }"
              append-icon="icon-arrow-down"
              ref="source"
              outlined
            ></v-select>

            <v-select
              v-model="client.insurance"
              @change="checkForm($v.client.insurance)"
              @blur="$v.client.insurance.$touch()"
              :items="getList('s3p6_select_4_value_', 3)"
              :class="{ invalid: $v.client.insurance.$dirty && !$v.client.insurance.required }"
              item-text="name"
              item-value="value"
              :label="'s3p6_label_7' | localize"
              :placeholder="'select' | localize"
              :menu-props="{ offsetY: true }"
              append-icon="icon-arrow-down"
              ref="insurance"
              outlined
            ></v-select>
        </div>
        <div class="form__group" v-else>
            <!-- Источник дохода -->
            <v-select
            v-model="client.source"
            @blur="$v.client.source.$touch()"
            @change="client.work = null"
            :items="getList('s3p6_select_1_value_', 4)"
            :class="{ invalid: $v.client.source.$dirty && !$v.client.source.required }"
            item-text="name"
            item-value="value"
            :label="'s3p6_label_4' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            ref="source"
            outlined
            ></v-select>
        </div>

        <!-- Выбор места работы, суммы дохода и стажа для наемых рабочих: -->
        <div v-if="client.source == 1 || client.source == 3">
            <div class="form__group">
              <v-text-field
                  v-model="client.workplace"
                  @blur="nextElementfocus('workplace', 'work')"
                  @keyup.enter="$event.target.blur()"
                  :label="'s3p6_label_9' | localize"
                  placeholder="Bankimonline"
                  ref="workplace"
                  outlined
              ></v-text-field>

              <v-select
                  v-model="client.work"
                  :items="getList('s3p6_select_6_value_', 8)"
                  item-text="name"
                  item-value="value"
                  :label="'s3p6_label_10' | localize"
                  :placeholder="'select' | localize"
                  :menu-props="{ offsetY: true }"
                  append-icon="icon-arrow-down"
                  ref="work"
                  outlined
              ></v-select>
            </div>
        </div>

        <!-- Сумма дохода и стаж для работающих: -->
        <div v-if="client.source == 2 || (client.work > 0 && client.work < 7)">
          <div class="form__group">
              <!-- Сумма дохода -->
              <div class="form__element">
                <v-text-field
                    v-model="client.income"
                    class="input_number input_slider"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    @keypress="onKeyPressHandler"
                    @keyup="onKeyUpPriceHandler('income', client.income)"
                    @change="syncSlider('income', 'income_slider', true)"
                    :label="'s3p6_text_6' | localize"
                    :placeholder="'example_text' | localize('10 000')"
                    append-icon="icon-₪"
                    ref="income"
                    outlined
                ></v-text-field>
                <v-slider
                    v-model="client.income_slider"
                    :min="income_min"
                    :max="income_max"
                    @input="syncSlider('income', 'income_slider')"
                    step="100"
                    class="slider--container"
                ></v-slider>
                <div class="slider--ticks">
                    <span>{{ income_min.toLocaleString() }}</span>
                    <span>{{ income_max.toLocaleString() }}</span>
                </div>
              </div>

              <!-- Стаж работы -->
              <div class="form__element">
                <v-text-field
                    v-model="client.experience"
                    class="input_number input_slider"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    @change="
                    syncSlider('experience', 'experience_slider', true)
                    "
                    :label="'s3p6_text_7' | localize"
                    :placeholder="'example_text' | localize('6')"
                    ref="experience"
                    outlined
                ></v-text-field>
                <v-slider
                    v-model="client.experience_slider"
                    :min="experience_min"
                    :max="experience_max"
                    @input="syncSlider('experience', 'experience_slider')"
                    class="slider--container"
                ></v-slider>
                <div class="slider--ticks">
                    <span>{{ experience_min }}</span>
                    <span>{{ experience_max }}</span>
                </div>
              </div>
          </div>
        </div>

        <div class="form__group">
            <v-select
              v-model="client.family"
              @change="checkForm($v.client.family); nextElementfocus('family', 'children')"
              @blur="nextElementfocus('family', 'children')"
              @keyup.enter="$event.target.blur()"
              :items="getList('s3p6_select_2_value_', 5)"
              :class="{ invalid: $v.client.family.$dirty && !$v.client.family.required }"
              item-text="name"
              item-value="value"
              :label="'s3p6_label_6' | localize"
              :placeholder="'select' | localize"
              :menu-props="{ offsetY: true }"
              append-icon="icon-arrow-down"
              ref="family"
              outlined
            ></v-select>

            <v-select
              v-model="client.children"
              @change="checkForm($v.client.children)"
              @blur="$v.client.children.$touch()"
              :items="[
                  { name: 0, value: 0 },
                  { name: 1, value: 1 },
                  { name: 2, value: 2 },
                  { name: 3, value: 3 },
                  { name: '3+', value: 4 },
              ]"
              :class="{ invalid: $v.client.children.$dirty && !$v.client.children.required }"
              item-text="name"
              item-value="value"
              :label="'s3p6_label_11' | localize"
              :placeholder="'select' | localize"
              :menu-props="{ offsetY: true }"
              append-icon="icon-arrow-down"
              ref="children"
              outlined
            ></v-select>
        </div>

        <!-- Информация о доходах партнера -->
        <div v-if="client.family == 1 || client.family == 3">
            <div class="form__group">
            <!-- Сумма дохода партнера -->
            <div class="form__element">
                <v-text-field
                v-model="client.partner_income"
                class="input_number input_slider"
                inputmode="numeric"
                pattern="[0-9]*"
                @keypress="onKeyPressHandler"
                @keyup="
                    onKeyUpPriceHandler(
                    'partner_income',
                    client.partner_income
                    )
                "
                @change="
                    syncSlider(
                    'partner_income',
                    'partner_income_slider',
                    true
                    )
                "
                :label="'s3p6_text_8' | localize"
                :placeholder="'example_text' | localize('10 000')"
                append-icon="icon-₪"
                ref="partner_income"
                outlined
                ></v-text-field>
                <v-slider
                v-model="client.partner_income_slider"
                :min="income_min"
                :max="income_max"
                @input="
                    syncSlider('partner_income', 'partner_income_slider')
                "
                step="100"
                class="slider--container"
                ></v-slider>
                <div class="slider--ticks">
                <span>{{ income_min.toLocaleString() }}</span>
                <span>{{ income_max.toLocaleString() }}</span>
                </div>
            </div>

            <!-- Стаж работы партнера -->
            <div class="form__element">
                <v-text-field
                v-model="client.partner_experience"
                class="input_number input_slider"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="
                    syncSlider(
                    'partner_experience',
                    'partner_experience_slider',
                    true
                    )
                "
                :label="'s3p6_text_9' | localize"
                :placeholder="'example_text' | localize('6')"
                ref="partner_experience"
                outlined
                ></v-text-field>
                <v-slider
                v-model="client.partner_experience_slider"
                @input="
                    syncSlider(
                    'partner_experience',
                    'partner_experience_slider'
                    )
                "
                :min="experience_min"
                :max="experience_max"
                class="slider--container"
                ></v-slider>
                <div class="slider--ticks">
                <span>{{ experience_min }}</span>
                <span>{{ experience_max }}</span>
                </div>
            </div>
            </div>
        </div>

        <div class="form__group">
            <v-select
              v-if="clientNumber > 1"
              v-model="client.relation"
              :items="getList('s3p6_select_5_value_', 7)"
              item-text="name"
              item-value="value"
              :label="'s3p6_label_8' | localize"
              :placeholder="'select' | localize"
              :menu-props="{ offsetY: true }"
              append-icon="icon-arrow-down"
              ref="relation"
              outlined
            ></v-select>
        </div>

        <p class="question">{{ "s3p6_text_2" | localize }}</p>
        <v-radio-group
            v-model="client.citizenship"
            v-on:change="checkForm()"
            :class="{ invalid: $v.client.citizenship.$dirty && !$v.client.citizenship.required }"
            ref="citizenship"
            row
        >
            <v-radio
            :label="'yes' | localize"
            value="1"
            on-icon="icon-radio-on"
            off-icon="icon-radio-off"
            ></v-radio>
            <v-radio
            :label="'no' | localize"
            value="0"
            on-icon="icon-radio-on"
            off-icon="icon-radio-off"
            ></v-radio>
        </v-radio-group>

        <p class="question">{{ "s3p6_text_3" | localize }}</p>
        <v-radio-group
            v-model="client.taxcountry"
            v-on:change="checkForm()"
            :class="{ invalid: $v.client.taxcountry.$dirty && !$v.client.taxcountry.required }"
            ref="taxcountry"
            row
        >
            <v-radio
            :label="'yes' | localize"
            value="1"
            on-icon="icon-radio-on"
            off-icon="icon-radio-off"
            ></v-radio>
            <v-radio
            :label="'no' | localize"
            value="0"
            on-icon="icon-radio-on"
            off-icon="icon-radio-off"
            ></v-radio>
        </v-radio-group>

        <p class="question">{{ "s3p6_text_4" | localize }}</p>
        <v-radio-group
            v-model="client.publics"
            v-on:change="checkForm()"
            :class="{ invalid: $v.client.publics.$dirty && !$v.client.publics.required }"
            ref="publics"
        >
            <v-radio
            :label="'s3p6_radio_1' | localize"
            value="1"
            on-icon="icon-radio-on"
            off-icon="icon-radio-off"
            ></v-radio>
            <v-radio
            :label="'no' | localize"
            value="0"
            on-icon="icon-radio-on"
            off-icon="icon-radio-off"
            ></v-radio>
            <v-radio
            :label="'s3p6_radio_2' | localize"
            value="2"
            on-icon="icon-radio-on"
            off-icon="icon-radio-off"
            ></v-radio>
        </v-radio-group>

        <div class="error__container error__container_button">
            <p class="error" v-if="error">{{ error | localize }}</p>
            <p class="error" v-if="!success && check_form">{{ 'field_required' | localize }}</p>
        </div>

        <button
            type="button"
            class="button button__form"
            :class="{ button__form_disabled: !success }"
            @click="submitHandler(false)"
        >
            {{ "form_continue" | localize }}
        </button>

        <button
            type="button"
            class="button button__form button__form_empty"
            @click="submitHandler(true)"
            v-if="clientCount > 1"
        >
            + {{ "form_add_partner" | localize }}
        </button>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import store from '@/store'

const streetRule = new RegExp(store.state.is_heb_lang ? '^[\u0590-\u05FF]+$' : '^[0-9а-яА-ЯёЁ\-]+$')

export default {
  name: 'clients',
  props: {
    clients: { type: Number },
    service: { type: String, required: true },
    nextlink: { type: String, required: true }
  },
  mixins: [formMixin, commonMixin, numberMixin],
  data: () => ({
    cities: [],
    dateFrom: null,
    dateTo: new Date().toISOString().substr(0, 10),
    dateMask: [/\d/, /\d/, ' / ', /\d/, /\d/, ' / ', /\d/, /\d/, /\d/, /\d/],
    clientNumber: 1,
    clientCount: 1,
    client: null,
    income_min: 1000,
    income_max: 100000,
    experience_min: 1,
    experience_max: 480,
    menu: false,
    error: null
  }),
  created () {
    const user = this.$cookies.get('user') || {}
    const data = this.$cookies.get('clients') || {}
    const client = data.clients && data.clients[this.clientNumber] ? data.clients[this.clientNumber] : {}

    // Для первого клиента используем ранее данные
    const clientName = this.clientNumber === 1 ? user.name : null

    this.client = {
      name: client.name || clientName || null,
      sex: client.sex || null,

      date: client.birthday || null,
      dateFormatted: this.formatDate(client.birthday || new Date().toISOString().substr(0, 10)),
      education: client.education || null,

      city: client.city || null,
      street: client.street || null,
      house: client.house || null,

      source: client.source || null,
      workplace: client.workplace || null,
      work: client.work || null,

      income: this.formatNumber(client.income || 10000),
      income_slider: client.income_slider || 10000,
      experience: client.experience || 6,
      experience_slider: client.experience_slider || 6,

      family: client.family || null,
      partner_income: this.formatNumber(client.partner_income || 10000),
      partner_income_slider: client.partner_income || 10000,
      partner_experience: client.partner_experience || 6,
      partner_experience_slider: client.partner_experience || 6,

      children: client.children || null,
      insurance: client.insurance || 1,
      relation: client.relation || null,

      citizenship: client.citizenship || null,
      taxcountry: client.taxcountry || null,
      publics: client.publics || null
    }

    this.clientCount = this.clients || 1
    this.success = !this.$v.client.$invalid

    // Начальная дата для выбора дня рождения = текущая - 100 лет
    const date = new Date()
    date.setFullYear(date.getFullYear() - 100)
    this.dateFrom = date.toISOString().substr(0, 10)
  },
  mounted () {
    const localeName = 'name_' + this.$store.state.lang
    this.axios
      .get('cities?sort=population&projection=id,' + localeName)
      .then((response) => {
        this.cities = response.data.map((e) => ({
          name: e[localeName],
          value: e.id
        }))
      })
      .catch((e) => {
        this.error = 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  },
  validations: {
    client: {
      name: { required },
      sex: { required },

      date: { required, validDate: v => formMixin.methods.isValidDate(v) },
      education: { required },

      city: { required },
      street: { required, alpha: val => streetRule.test(val) },
      house: { required },

      source: { required },
      family: { required },

      insurance: { required },
      children: { required },

      citizenship: { required },
      taxcountry: { required },
      publics: { required }
    }
  },
  beforeDestroy () {
    // Save clients data
    this.saveClients()
  },
  methods: {
    setDefaults () {
      if (
        this.client.source &&
        this.client.source === 4
      ) {
        this.client.workplace = null
        this.client.work = null
        this.client.income = null
        this.client.experience = null
      }
      if (
        this.client.family &&
        this.client.family !== 1 &&
        this.client.family !== 3
      ) {
        this.client.partner_income = null
        this.client.partner_experience = null
      }
      if (this.service === 'credit' || this.service === 'refCredit') {
        this.client.insurance = 1
      }
    },
    onKeyUpPriceHandler (field, value) {
      if (value) {
        const number = this.parseNumber(value)
        this.client[field] = this.formatNumber(number)
      }
    },
    syncSlider (num, slider, reverse = false) {
      if (reverse) {
        this.client[slider] = this.parseNumber(this.client[num])
      } else {
        this.client[num] = this.formatNumber(this.client[slider])
      }
    },
    nextElementfocus (current, next) {
      this.$v.client[current].$touch()
      if (!this.$v.client[current].$invalid) {
        this.$refs[current].blur()
        setTimeout(() => { this.$refs[next].focus() }, 100)
      }
    },
    firstErrorClientFocus (touchCurrentField) {
      if (!touchCurrentField) this.$v.client.$touch()
      for (const key in Object.keys(this.$v.client)) {
        const input = Object.keys(this.$v.client)[key]
        if (input.includes('$')) return false
        if (touchCurrentField) this.$v.client[input].$touch()
        if (this.$v.client[input].$error && this.$refs[input]) {
          this.$refs[input].focus()
          break
        }
      }
    },
    saveClients () {
      this.client.income = this.parseNumber(this.client.income)
      this.client.partner_income = this.parseNumber(this.client.partner_income)
      this.client.partner_income = this.client.family === 1 || this.client.family === 3 ? parseInt(this.client.partner_income) : 0
      this.client.partner_experience = this.client.family === 1 || this.client.family === 3 ? parseInt(this.client.partner_experience) : 0
      this.client.birthday = this.client.date

      const data = this.$cookies.get('clients') || {}
      if (!(data.clients instanceof Array)) data.clients = []
      data.clients[this.clientNumber] = this.client
      this.$cookies.set('clients', data)

      return data
    },
    submitHandler (addPartner = false) {
      this.check_form = true
      this.setDefaults()
      if (this.$v.client.$invalid) {
        this.firstErrorClientFocus(false)
        this.success = false
        return
      }

      const data = this.saveClients()

      // Если клиентов более 1 и не пройдены все клиенты (или сценарий добавления клиента)
      if ((this.clientCount > 1 && this.clientCount > this.clientNumber) || addPartner) {
        this.clientNumber++
        this.client = data.clients[this.clientNumber] ? data.clients[this.clientNumber] : {}
        this.success = !this.$v.client.$invalid

        // Сброс валидации для нового участника
        this.$v.$reset()

        // Скролл наверх
        const scrollTo = this.$el.querySelector('.step')
        if (scrollTo) scrollTo.scrollIntoView()
      } else {

        // Сохранение участников услуги в бд
        let clients = data.clients.filter(c => c && 'name' in c)
        if (this.clientCount == 1) clients = [clients.find(c => c.name)]
        const requestData = {
          user: this.$store.state.user.id || null,
          service: this.$store.state.services[this.service],
          data: clients
        }
        this.$store.commit('loading', true)
        this.axios
          .post('clients', requestData)
          .then((response) => {
            data.saved_clients = response.data.clients
            this.$cookies.set(this.service, data)
            this.$store.commit('loading', false)
            this.$router.push(this.nextlink)
          })
          .catch((error) => {
            this.$store.commit('loading', false)
            this.error = error.response.data.error ?? 'server_error'
            this.$scrollTo('.error__container', 300, { offset: -400 })
          })
      }
    }
  }
}
</script>
